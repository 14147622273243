import { Component, Host, h } from '@stencil/core';

@Component({
  tag: 'x-table-common',
  styleUrl: 'x-table-common.sass',
})
export class XTableCommon {
  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
