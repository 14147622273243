x-table-common
  table
    width: 100%
    th + th,
    td + td
      border-left: 1px solid var(--divider-dark-grey)
    th,
    td
      box-sizing: border-box
      color: var(--on-surface-dark)
    th
      +preset(5)
      background-color: var(--surface-light-grey)
      text-align: left
      height: 48px
      padding: 6px 12px
      vertical-align: middle
    td
      +preset(2)
      border-top: 1px solid var(--divider-dark-grey)
      vertical-align: top
      padding: 12px
